import gql from 'graphql-tag';

const request = {
	properties: {
		name: 'filter>mutate_nm'
	},

	validation: {},

	gql: {
		schema: gql`
			mutation AuctionFilterMutateNm(
				$real_estate_filter_id: ID,
				$real_estate_filter_nm: String
			) {
				request(
					real_estate_filter_id: $real_estate_filter_id,
					real_estate_filter_nm: $real_estate_filter_nm
				) {
					real_estate_filter_nm
				}
			}
		`
	}
};

export default request;
