import React, { useState, useEffect } from 'react';
import MultiSelect from 'react-multi-select-component';
import PropTypes from 'prop-types';

const SelectMultiple = ({ name, list, state, selected, placeholder, onChange, propsByType, crToOptions }) => {
	const [changed, setChanged] = useState(false);
	const [options, setOptions] = useState(crToOptions(list)); // redesign object in array [list.map(({ text, id }) => { return { label: text, value: id }; })]
	const [sel, setSel] = useState(selected ? crToOptions(selected) : []); // redesign object in array [selected.map(({ text, id }) => { return { label: text, value: id }; })]

	// On local state change, update outside/parent component
	useEffect(() => {
		if (changed) {
			onChange({ target: { name, value: sel.map(({ label, value }) => { return value; }) } });
		}
	}, [sel]);

	// On selected (from outside/parent) change (when prop selected is changed, not when local state is changed)
	useEffect(() => {
		if (selected.length !== sel.length) {
			setSel(selected ? crToOptions(selected) : []); // selected.map(({ text, id }) => { return { label: text, value: id }; })
		}
	}, [selected]);

	// If list is changed, options should be rerendered again
	useEffect(() => {
		setOptions(crToOptions(list)); // list.map(({ text, id }) => { return { label: text, value: id }; })
	}, [list]);

	return (
		<MultiSelect
			disableSearch={propsByType.disableSearch}
			hasSelectAll={propsByType.selectAll || false}
			options={options}
			value={sel}
			onChange={(opts) => { setSel(opts); if (changed === false) setChanged(true); }}
			labelledBy={placeholder}
			className={`multi-select${sel.length > 0 ? ' active' : ''}`}
			overrideStrings={{
				selectSomeItems: placeholder,
				allItemsAreSelected: 'All items are selected.', // translate?
				selectAll: 'Select All', // translate?
				search: 'Search', // translate?
				clearSearch: 'Clear Search' // translate?
			}}
		/>
	);
};

SelectMultiple.defaultProps = {
	state: null,
	selected: null,
	placeholder: null,
	propsByType: {}
};

SelectMultiple.propTypes = {
	name: PropTypes.string.isRequired,
	state: PropTypes.shape({}),
	list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	placeholder: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	selected: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.arrayOf(PropTypes.string)
	]),
	propsByType: PropTypes.shape({}),
	crToOptions: PropTypes.func.isRequired
};

export default SelectMultiple;
