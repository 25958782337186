import gql from 'graphql-tag';

const request = {
	properties: {
		name: 'filter>mutate'
	},

	validation: {},

	gql: {
		schema: gql`
			mutation AuctionFilterMutate(
				$active: Boolean,
				$real_estate_filter_id: ID,
				$real_estate_filter_nm: String,
				$type_id: ID,
				$subtype_id: [ID],
				$geo_location_level1_id: ID,
				$geo_location_level2_id: ID,
				$geo_location_level3_id: ID,
				$price: [String],
				$auction_expire_soon: String,
				$auction_offer_type: [ID],
				$auction_sequence: [ID],
				$auction_share_sale_all: String
			) {
				request(
					active: $active,
					real_estate_filter_id: $real_estate_filter_id,
					real_estate_filter_nm: $real_estate_filter_nm,
					type_id: $type_id,
					subtype_id: $subtype_id,
					geo_location_level1_id: $geo_location_level1_id,
					geo_location_level2_id: $geo_location_level2_id,
					geo_location_level3_id: $geo_location_level3_id,
					price: $price,
					auction_expire_soon: $auction_expire_soon,
					auction_offer_type: $auction_offer_type,
					auction_sequence: $auction_sequence,
					auction_share_sale_all: $auction_share_sale_all
				) {
					real_estate_filter_id
				}
			}
		`
	}
};

export default request;
