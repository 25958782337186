import { useState, useEffect } from 'react';

import useCoderegister from './useCoderegister';
import useAPI from './useAPI';
import useApp from './useApp';
import { useStore as filterStore } from '../../contexts/filter';
import { useStore as loaderStore } from '../../contexts/loader';
import requestMutate from '../../requests/mutation/filter/mutate';
import requestMutateNm from '../../requests/mutation/filter/mutate-nm';
import requestFilters from '../../requests/query/filter/items';
import requestDelete from '../../requests/mutation/filter/delete';
import { formatPrice } from '../../helpers/number';

const useFilter = () => {
	const { mutate, query } = useAPI();
	const { clientCrFields, getCoderegisters } = useCoderegister();
	const { appDispatch } = useApp();

	// Global loader

	const { data: { isCoderegisterLoaded } } = loaderStore();

	// Filter option settings

	const { data, dispatch } = filterStore();
	const { filter, fieldsProps } = data;

	const [settings] = useState({
		// Default
		type_id: { group: 'default' },
		subtype_id: { group: 'default' },
		geo_location_level1_id: { group: 'default' }, // region
		geo_location_level3_id: { group: 'default' }, // municipality

		// Filter
		auction_expire_soon: { group: 'filter' },
		auction_share_sale_all: { group: 'filter' },
		auction_offer_type: { group: 'filter' },
		auction_sequence: { group: 'filter' },
		price: { group: 'filter', onRemoveResetCntInc: true }
	});

	// Skip fields, which are not "filter options", but are returned from server

	const [skip] = useState(['real_estate_filter_id', 'real_estate_filter_nm', 'result_cnt', '__typename']);

	// Tags

	const setFilterTags = (fltr) => {
		const f = fltr || filter;
		const tags = [];
		// Normalize values - if CR value, still leave ID, which will be rewrited via getFilterNodeText in search-filters/tag/list/tag with text. If range (a-b), convert to array and format (ex. price)
		if (f) {
			Object.keys(f).map((key) => {
				if (!skip.includes(key) && f[key]) {
					const value = f[key];

					switch (key) {
					default: {
						tags.push({
							name: key,
							value,
							valuePrint: null
						});
						break;
					}

					case 'price': {
						if (Array.isArray(value)) {
							tags.push({
								name: key,
								value: null,
								valuePrint: `${formatPrice(value[0])} € - ${formatPrice(value[1])} €`
							});
						}
						break;
					}
					}
				}

				return true;
			});
		}

		return tags;
	};

	// Filter node handle

	const getFilterNodeValue = (name, returnType = 'string') => {
		if (filter[name]) {
			return returnType === 'string' ? filter[name].value : true;
		}

		return returnType === 'string' ? '' : false;
	};

	const getFilterNodeText = (name, value, crList) => {
		const c = crList || coderegisters;
		const field2cr = clientCrFields[name] || name; // ex. options = "client cr" - used only, to show tag name

		if (coderegisters[field2cr]) {
			const search = c[field2cr].filter(row => row.id === value);
			if (search[0]) {
				return search[0].text;
			}
		}

		return value;
	};

	// Coderegisters

	const getCoderegistersList = (typeId, geoLocationLevel1Id) => {
		// Server
		const l = getCoderegisters({
			aa_real_estate_type: typeId || getFilterNodeValue('type_id'),
			aa_real_estate_subtype: getFilterNodeValue('subtype_id'),
			aa_geo_location_level1: geoLocationLevel1Id || getFilterNodeValue('geo_location_level1_id'), // region
			aa_geo_location_level3: getFilterNodeValue('geo_location_level3_id') // municipality
		});

		// Local, in client: used, to show tag name (!! id/text = cr in db !!)
		l.auction_expire_soon = [
			{ id: '1', text: 'Oglasi, ki kmalu potečejo' }
		];

		l.auction_share_sale_all = [
			{ id: '1', text: 'Samo 100% delež na dražbi' }
		];

		l.auction_sequence = [
			{ id: '1', text: 'Prva dražba' },
			{ id: '2', text: 'Druga dražba' }
		];

		l.auction_offer_type = [
			{ id: '4', text: 'Zavezujoče ponudbe' },
			{ id: '5', text: 'Nezavezujoče ponudbe' }
		];

		return l;
	};

	const [coderegisters, setCoderegisters] = useState(getCoderegistersList);
	useEffect(() => { setCoderegisters(getCoderegistersList()); }, [filter, isCoderegisterLoaded]);

	const setCoderegistersList = (typeId, geoLocationLevel1Id) => {
		return getCoderegistersList(typeId, geoLocationLevel1Id);
	};

	// Filter handle
	const [changed, setChanged] = useState(false);

	const setFilter = (f) => {
		dispatch({
			type: 'SET_FILTER',
			payload: {
				filter: f
			}
		});
	};

	const isFilterActive = (e) => {
		const { target } = e;
		const { type, value, checked } = target;

		if (type === 'checkbox' && !checked) return false;
		if ((type === 'select' || type === 'select-one' || type === 'input-range-slider') && (!value || value.length === 0)) return false;
		if (type === 'delete') return false;

		return true;
	};

	const cntByGroup = (group) => {
		let cnt = 0;

		Object.values(filter).map((row) => {
			const { name } = row;
			if (typeof settings[name] !== 'undefined' && settings[name].group === group) {
				if (typeof filter[name] === 'object') {
					cnt += Object.values(filter[name].value).length;
				} else if (filter[name].value) {
					cnt += 1;
				}
			}

			return true;
		});

		return cnt;
	};

	const updateFilter = (e) => {
		const { type, name, value } = e.target;

		dispatch({ type: 'SET_LAST_FILTER', payload: { name, value } });

		// On delete
		if (type && type === 'delete') {
			if (settings[name] && settings[name].onRemoveResetCntInc) { // ex. used for input-range-slider to update key={fieldsProps.price.resetCnt} to rerender and reset componetn (remove values)
				dispatch({ type: 'SET_FILTER_FIELDS_PROPS', payload: { name, value: { ...fieldsProps[name], resetCnt: fieldsProps[name] ? fieldsProps[name].resetCnt + 1 : 1 } } });
			}
		}

		const group = settings[name] ? settings[name].group : ' default';
		let v = value; // could be changed during handle
		const f = { ...filter };

		// Flag, that user changed something ...
		if (changed === false) {
			setChanged(true);
		}

		// Remove all childs (ex. subtypes, when type is changed, cities, when region ...)
		const fieldsLink = {
			type_id: 'subtype_id',
			geo_location_level1_id: 'geo_location_level3_id' // region -> municipality
		};

		if (['type_id', 'geo_location_level1_id'].includes(name) && fieldsLink[name]) {
			delete f[fieldsLink[name]];
		}

		// Check, if range - then split to array
		if (typeof v === 'string' && v.indexOf('-') !== -1) {
			v = v.split('-');
		}

		// Check, if value is array - ex. multiselect dropdown (single html element, which return tag/filter array)
		if (Array.isArray(v)) {
			setFilter({ ...f, [name]: { name, value: v, group } });
			return true;
		}

		// Collector - ex. checkboxes with same name (separated html elements, saved to same tag/filter as array) OR when tag from multiple value is deleted
		if (name.slice(-2) === '[]') {
			const n = name.slice(0, -2); // name[] --> name
			const collector = (f[n] && f[n].value && Array.isArray(f[n].value)) ? f[n].value : [];

			if (isFilterActive(e)) {
				collector.push(v);
			} else {
				collector.splice(collector.indexOf(v), 1);
			}

			setFilter({ ...f, [n]: { name: n, value: collector, group } });
			return true;
		}

		// Default
		if (isFilterActive(e)) {
			setFilter({ ...f, [name]: { name, value: v, group } });
		} else {
			delete f[name];
			setFilter(f);
		}

		return true;
	};

	const resetFilter = () => {
		const f = Object.values(filter).filter(row => settings[row.name].group !== 'filter');
		const newF = {};
		f.map((row) => {
			newF[row.name] = row;
			return true;
		});

		setFilter(newF);
	};

	const clearAll = () => {
		dispatch({ type: 'RESET_FILTER' });
	};

	// Handle filter object (to show tags/send to API or use in form)

	// ex. subtype_id: ["1", "2"] --> subtype_id: {name: "subtype_id", value: ["1", "2"], group: "default"}
	const getFilterFormReady = (f, s) => {
		const r = {};
		const source = f || filter;
		const skp = s || []; // skip

		Object.keys(source).map((key) => {
			if (!skp.includes(key) && source[key]) {
				const v = (typeof source[key] === 'string' && source[key].indexOf(',') !== -1) ? source[key].split(',') : source[key];
				r[key] = { name: key, value: v, group: settings[key] ? settings[key].group : 'default' };
			}
			return true;
		});

		return r;
	};

	// ex. subtype_id: {name: "subtype_id", value: ["1", "2"], group: "default"} --> subtype_id: ["1", "2"]
	const getFilterAPIReady = (f) => {
		const r = {};

		Object.values(f || filter).map((row) => {
			const { name, value } = row;
			r[name] = value;

			return true;
		});

		return r;
	};

	// Save filter

	const saveFilter = (callback) => {
		mutate(requestMutate, getFilterAPIReady(filter), (d, e) => {
			if (callback) callback(d, e);
			appDispatch({ type: 'HEADER_PROFILE_CNT_REFRESH_INC' });
		});
	};

	// Delete filter

	const deleteFilter = (id, callback) => {
		mutate(requestDelete, { real_estate_filter_id: id }, (d, e) => {
			if (callback) callback(d, e);
			appDispatch({ type: 'HEADER_PROFILE_CNT_REFRESH_INC' });
		});
	};

	// Rename filter

	const renameFilter = (variables, callback) => {
		mutate(requestMutateNm, variables, callback);
	};

	// Get filters

	const getFilters = (callback) => {
		query(requestFilters, {}, callback);
	};

	return { data, dispatch, setFilter, updateFilter, saveFilter, getFilters, renameFilter, deleteFilter, isFilterActive, resetFilter, clearAll, getFilterAPIReady, getFilterFormReady, setFilterTags, getFilterNodeValue, getFilterNodeText, coderegisters, setCoderegistersList, changed, cntByGroup };
};

export default useFilter;
