import gql from 'graphql-tag';

const request = {
	properties: {
		name: 'filter>delete'
	},

	validation: {},

	gql: {
		schema: gql`
			mutation AuctionFilterDelete(
				$real_estate_filter_id: ID
			) {
				request(
					real_estate_filter_id: $real_estate_filter_id
				) {
					status
				}
			}
		`
	}
};

export default request;
